<!--
This component cannot have protected stuff. This is the component that the user sees. 
-->
<template>
  <div>
    <!-- Start of the product grid -->
    <div v-for="item in cart" :key="item.varientId" class="grid relative py-3 border-b grid-cols-5">
      <!-- Delete product button -->
      <button
        class="absolute right-0 top-0 sm:top-1 mt-2 sm:mt-1 hover:bg-red-200 cursor-pointer rounded py-1 px-2"
        @click="removeFromCart(item.variantId)"
      >
        <font-awesome-icon icon="times" />
      </button>

      <!-- Img -->
      <div
        class="col-span-1 row-span-2 w-12 h-12 p-2 rounded-lg shadow overflow-hidden self-center sm:mx-auto flex items-center justify-center"
      >
        <router-link :to="`/product/${item.productId}`">
          <img v-lazy="item.image" alt="" />
        </router-link>
      </div>

      <!-- Title -->
      <div class="col-span-3 row-span-1 sm:col-span-3 sm:my-auto font-semibold pr-8 sm:pr-0">
        {{ item.name }}
      </div>

      <div class="sm:flex justify-between items-center col-span-2">
        <!-- Product Num -->
        <div class="text-gray-600">
          {{ item.productNum }}
        </div>

        <!-- Price -->
        <div class="font-semibold" v-if="item.price === 0">Free</div>
        <div class="font-semibold" v-else>${{ (item.price * 1).toFixed(2) }}</div>
      </div>

      <!-- Quantity -->
      <div
        class="flex items-end sm:items-center justify-end sm:justify-center sm:mr-6 md:mr-0 col-span-2"
      >
        <div class="flex bg-gray-200 rounded py-1 justify-center sm:justify-start">
          <button @click="decrementQuantity(item.variantId)" class="px-3 py-px text-md">
            <font-awesome-icon icon="minus" />
          </button>
          <div class="bg-white w-10 md:w-12 text-center text-md">
            {{ item.quantity }}
          </div>
          <button @click="incrementQuantity(item.variantId)" class="px-3 py-px text-md">
            <font-awesome-icon icon="plus" />
          </button>
        </div>
      </div>
    </div>
    <!-- End of product grid -->
  </div>
</template>

<script>
export default {
  props: {},
  data: function () {
    return {};
  },
  computed: {
    cart() {
      if (this.items != null) {
        return this.items;
      }
      return this.$store.getters['order/getCart'];
    },
  },
  watch: {},
  methods: {
    decrementQuantity(variantId) {
      this.$store.dispatch('order/alterQuantity', {
        variantId,
        quantity: -1,
      });
    },
    incrementQuantity(variantId) {
      this.$store.dispatch('order/alterQuantity', {
        variantId,
        quantity: 1,
      });
    },
    setQuantity(variantId, event) {
      this.$store.dispatch('order/setQuantity', {
        variantId: variantId,
        quantity: event.target.value,
      });
    },
    removeFromCart(variant_id) {
      this.$store.dispatch('order/removeFromCart', variant_id);
    },
    removeScrolling() {
      const inputs = document.getElementsByClassName('fulfilled-input');
      if (inputs.length > 0) {
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].addEventListener('mousewheel', function () {
            this.blur();
          });
        }
      }
    },
  },
  mounted() {
    this.removeScrolling();
  },
};
</script>
<style scoped>
div.grid {
  grid-template-rows: repeat(2, minmax(0, auto));
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For anything larger than iPad device */
@media only screen and (min-width: 600px) {
  div.grid {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
}
</style>
