<template>
  <div class="md:p-12 lg:px-32">
    <!-- tabs for desktop -->
    <ul class="hidden md:flex" v-if="commercial">
      <li
        class="mr-1 rounded-t-xl overflow-hidden hover:text-blue-800 text-primary text-sm"
        v-for="(cart, index) in carts"
        :key="cart._id"
        @click="changeCart(index)"
      >
        <div
          class="inline-block py-2 px-10 cursor-pointer capitalize relative"
          :class="isSelected(index)"
        >
          {{ cart.orderName ? cart.orderName : 'Cart' }}
          <button
            v-if="cart.orderName"
            class="trash-can absolute right-0 top-0 py-1 m-1 px-2 hover:bg-blue-200 rounded-full"
            @click="showSave = !showSave"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
        </div>
      </li>
    </ul>
    <div
      v-else-if="order.cart.length > 0"
      class="hidden md:block overflow-hidden hover:text-blue-800 text-primary text-sm rounded-t"
    >
      <h3
        class="inline-block py-2 px-10 capitalize relative text-action w-full text-center"
        :class="{ 'cursor-pointer hover:text-action-dark': commercial }"
      >
        My Cart
      </h3>
    </div>
    <!-- End Tabs for desktop -->

    <!-- Nav for mobile -->
    <div
      class="relative w-full md:hidden bg-white text-center shadow-md px-4 z-1"
      v-if="commercial"
    >
      <h4 class="text-primary py-4 capitalize">
        {{ order.orderName }}
      </h4>
      <button
        v-if="order.orderName !== 'Cart'"
        class="absolute left-0 top-0 py-1 m-1 px-2 hover:bg-blue-200 rounded-full my-3"
        @click="showSave = !showSave"
      >
        <font-awesome-icon icon="pencil-alt" class="text-primary" />
      </button>
      <button
        @click="showMobileNav = !showMobileNav"
        v-if="mobileCarts.length > 0"
        class="absolute right-1 top-1"
      >
        <font-awesome-icon
          icon="chevron-down"
          class="mx-2 duration-150 transition-transform text-primary"
          :class="showMobileNav ? 'flip' : null"
        />
      </button>

      <transition name="expandDownLarge">
        <div v-if="showMobileNav">
          <h4
            v-for="cart in mobileCarts"
            :key="cart._id"
            class="font-thin text-primary border-primary border-t py-4 capitalize"
            @click="changeCartMobile(cart.orderName)"
          >
            {{ cart.orderName }}
          </h4>
        </div>
      </transition>
    </div>
    <div v-else class="w-full md:hidden bg-white relative text-center shadow-md z-1">
      <h4 class="text-primary py-4">Cart</h4>
    </div>
    <!-- End Nav for mobile -->

    <div class="bg-white p-4 mb-6 relative rounded-b rounded-tr">
      <div v-if="order.cart.length > 0">
        <!-- Start of cart -->
        <CartLineItem />
        <!-- End of cart -->

        <!-- Start of totals -->

        <OrderTotals
          :products="products"
          :shipping="0"
          :discount="0"
          :taxRate="order.taxRate"
          class="my-4"
        />
        <!-- End of totals -->
        <div
          class="px-2 sm:px-8 md:px-12 my-3 grid grid-cols-2 sm:grid-cols-3 grid-rows-2 sm:grid-rows-1 gap-y-4 sm:gap-y-0 sm:w-2/3 sm:ml-auto"
        >
          <div>
            <button
              class="text-red p-1 my-auto w-32 mx-auto sm:mx-0"
              v-if="order._id && showCheckoutButton"
              @click="deleteCart()"
            >
              Delete Cart
            </button>
          </div>
          <div>
            <button
              v-if="commercial && order.orderName === 'Cart'"
              class="text-primary p-1 my-auto w-32 mx-auto sm:mx-0"
              @click="saveCart"
            >
              Save Cart
            </button>
          </div>
          <button
            @click="checkout"
            v-if="showCheckoutButton"
            class="btn-action rounded-full w-full col-span-2 sm:col-span-1 sm:w-40 my-auto"
          >
            Checkout
          </button>
        </div>
      </div>
      <div v-else class="text-center p-8">
        <h3>Your cart is empty</h3>

        <div class="text-center w-full">
          <div v-if="commercial">Access a saved cart or add new items</div>
          <div v-else><router-link to="/categories">Add new items</router-link></div>
          <img
            src="/images/static/empty.svg"
            class="w-64 h-64 mx-auto mt-4"
            alt="Image of an empty cart"
          />
        </div>
      </div>
    </div>

    <div class="pt-4 md:pt-10 lg:-mx-6">
      <h4 class="ml-6">Suggested</h4>
      <div class="carousel-wrapper">
        <carousel :products="suggestedItems" :white="false" :loading="suggestedLoading" />
      </div>
    </div>

    <!-- Save Cart desktop -->
    <PopUp v-if="showSave" @close="showSave = !showSave">
      <div class="w-full flex flex-col md:bg-gray-200 md:p-4 p-8 py-4">
        <label for="cartName" class="text-primary md:text-lg font-medium my-2 text-3xl"
          >Cart Name</label
        >
        <input
          type="text"
          id="cartName"
          class="styled rounded shadow md:mt-2 mt-10"
          v-model="cartName"
        />
        <div class="text-right mt-8">
          <button class="btn-action rounded" @click="saveCart()">Save Cart</button>
        </div>
      </div>
    </PopUp>
    <!-- End Save cart Desktop -->
  </div>
</template>

<script>
import Carousel from '@/components/items/ProductCarousel';
import PopUp from '@/components/shared/PopUp';
import CartLineItem from './CartLineItem';
import OrderTotals from '@/components/shared/NewOrderTotals';

export default {
  data() {
    return {
      cart: null,
      cartName: null,
      showMobileNav: false,
      showSave: false,
    };
  },
  computed: {
    //order structure can be found in store/modules/order
    products() {
      return this.$store.getters['order/getCart'];
    },
    order() {
      return this.$store.getters['order/getOrder'];
    },
    suggestedItems() {
      return this.$store.getters['products/getSuggested'];
    },
    user() {
      return this.$store.getters['users/getUser'];
    },
    carts() {
      const carts = this.$store.getters['order/getCarts'];

      if (carts === null && this.commercial) {
        this.$store.dispatch('order/getCarts');
      }
      return carts;
    },
    mobileCarts() {
      const carts = this.$store.getters['order/getCarts'];

      if (carts === null && this.commercial) {
        this.$store.dispatch('order/getCarts');
      }

      if (carts === null) {
        return new Array();
      }

      return carts.filter((el) => {
        return el.orderName !== this.order.orderName;
      });
    },
    commercial() {
      return this.$store.getters.getCommercial;
    },
    showCheckoutButton() {
      if (!this.user) {
        return true;
      }
      if (this.user.accountRole === 'Secondary') {
        return false;
      }
      return true;
    },
    suggestedLoading() {
      return this.$store.getters['products/getSuggestedLoading'];
    },
  },
  watch: {},
  methods: {
    saveCart() {
      this.order.status = 'Saved';
      if (this.order._id) {
        if (this.cartName) {
          this.order.orderName = this.cartName;

          this.$store.dispatch('order/updateOrder', this.order);
          this.showSave = false;
        }
        return;
      }
      //error if you have too many carts
      if (this.carts.length > 5) {
        this.$store.commit('setError', {
          message: `You can only have 5 saved carts.`,
          color: 'red',
          duration: 6500,
        });
        return;
      }
      this.showSave = true;
      if (!this.cartName) {
        return;
      }
      if (this.isNotUniqueName()) {
        this.$store.commit('setError', {
          message: `The name of this cart must be unique, ${this.cartName} is currently being used as another cart.`,
          color: 'red',
        });
        return;
      }

      this.order.orderName = this.cartName;
      this.$store.dispatch('order/saveCart', this.order);
      this.$store.commit('order/clearOrder');
      this.showSave = false;
    },
    changeCart(index) {
      this.$store.commit('order/setCartIndex', index);
      this.$store.commit('order/setUser', this.user);
    },

    changeCartMobile(orderName) {
      for (let i = 0; i < this.carts.length; i++) {
        if (this.carts[i].orderName === orderName) {
          this.$store.commit('order/setCartIndex', i);
          this.$store.commit('order/setUser', this.user);
        }
      }
      this.showMobileNav = false;
    },

    isSelected(index) {
      if (this.$store.state.order.selectedCartIndex === index) {
        return 'bg-white text-action hover:text-action-dark border-white border-b trash-can-wrapper';
      }

      return 'bg-gray-100 text-gray-800 hover:text-primary-dark border-gray-500 shadow-outline ';
    },
    deleteCart() {
      if (!confirm('Are you sure you would like to delete this cart?')) {
        return;
      }
      this.$store.dispatch('order/deleteOrder', this.order._id);
    },

    checkout() {
      this.$store.commit('order/setUser', this.user);
      this.$router.push({ path: '/checkout/summary' });
    },
    isNotUniqueName() {
      const cartName = this.cartName;
      if (!this.order.orderName) {
        return true;
      }

      if (
        this.carts.some((cart) => {
          if (cart.orderName.toLowerCase() === cartName.toLowerCase()) {
            return true;
          }
        })
      )
        return true;
    },
  },
  components: {
    Carousel,
    PopUp,
    OrderTotals,
    CartLineItem,
  },
  mounted() {
    if (this.order) {
      this.$store.dispatch('products/getSuggested');
    }
  },
};
</script>

<style scoped>
/* For anything larger than mobile device */
@media only screen and (min-width: 600px) {
  .carousel-wrapper::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #edf2f7);
    width: 15px;
  }

  .carousel-wrapper::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #edf2f7);
    width: 15px;
  }
}
</style>
